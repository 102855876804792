import { CountryCodes } from "../constant/AppConstant";

export class ProfileInfoModel {
    constructor() {
        this.companyName = '';
        // Business license
        this.businessLicenseNo = '';
        this.businessLicenseDateOfIssue = '';
        this.businessLicensePlaceOfIssue = '';
        this.businessLicenseURL = '';
        // Pharma business license
        this.pharmaBusinessLicenseNo = '';
        this.pharmaBusinessLicenseDateOfIssue = '';
        this.pharmaBusinessLicensePlaceOfIssue = '';
        this.pharmaBusinessLicenseURL = '';
        // GDP / GPP / GSP license
        this.gdpGppGspNo = '';
        this.gdpGppGspDateOfIssue = '';
        this.gdpGppGspPlaceOfIssue = '';
        this.gdpGppGspURL = '';
        // Treatment license
        this.treatmentLicenseNo = '';
        this.treatmentLicenseDateOfIssue = '';
        this.treatmentLicensePlaceOfIssue = '';
        this.treatmentLicenseURL = '';
        // Medical license
        this.medicalLicenseNo = '';
        this.medicalLicenseDateOfIssue = '';
        this.medicalLicensePlaceOfIssue = '';
        this.medicalLicenseURL = '';
        this.address = '';
        this.ward = '';
        this.district = '';
        this.city = '';
        this.state = '';
        this.country = 'Viet Nam';
        this.postalCode = '';
        this.locCodes = new LocCodesModel();
    }

    static validate(obj, error, isDoctor = false) {
        let result = true;

        if (obj.companyName == null || obj.companyName === '') {
            error.companyName = 'Company Name is required';
            result = false;
        }
        if (obj.address == null || obj.address === '') {
            error.address = 'Address is required';
            result = false;
        }
        if (obj.city == null || obj.city === '') {
            error.city = 'City is required';
            result = false;
        }
        if (obj.businessLicenseNo == null || obj.businessLicenseNo === '') {
            error.businessLicenseNo = 'Business License No is required';
            result = false;
        }
        if (obj.pharmaBusinessLicenseNo == null || obj.pharmaBusinessLicenseNo === '') {
            error.pharmaBusinessLicenseNo = 'Pharma Business License No is required';
            result = false;
        }
        if (obj.gdpGppGspNo == null || obj.gdpGppGspNo === '') {
            error.gdpGppGspNo = 'GDP/GPP/GSP No is required';
            result = false;
        }
        if (obj.treatmentLicenseNo == null || obj.treatmentLicenseNo === '') {
            error.treatmentLicenseNo = 'Treatment License No is required';
            result = false;
        }
        if (isDoctor && (obj.medicalLicenseNo == null || obj.medicalLicenseNo === '')) {
            error.medicalLicenseNo = 'Medical License No is required';
            result = false;
            // if (obj.treatmentLicenseNo == null || obj.treatmentLicenseNo === '') {
            //     error.treatmentLicenseNo = 'Treatment License No is required';
            //     result = false;
            // }
            // if (obj.medicalLicenseNo == null || obj.medicalLicenseNo === '') {
            //     error.medicalLicenseNo = 'Medical License No is required';
            //     result = false;
            // }
        }

        return result;
    }
};

export class BillingInfoModel {
    constructor() {
        this.name = '';
        this.taxCode = '';
        this.address = '';
        this.ward = '';
        this.district = '';
        this.city = '';
        this.state = '';
        this.postalCode = '';
        this.locCodes = new LocCodesModel();
    }

    static validate(obj, error) {
        let result = true;

        if (obj.name == null || obj.name === '') {
            error.name = 'Name is required';
            result = false;
        }
        if (obj.taxCode == null || obj.taxCode === '') {
            error.taxCode = 'Tax Code is required';
            result = false;
        }
        if (obj.address == null || obj.address === '') {
            error.address = 'Address is required';
            result = false;
        }
        if (obj.city == null || obj.city === '') {
            error.city = 'City is required';
            result = false;
        }

        return result;
    }
};

export class ShippingInfoModel {
    constructor() {
        this.contactName = '';
        this.contactNumber = '';
        this.address = '';
        this.ward = '';
        this.district = '';
        this.city = '';
        this.state = '';
        this.country = 'Viet Nam';
        this.postalCode = '';
        this.locCodes = new LocCodesModel();
    }

    static validate(obj, error, onlyCheckCity = false) {
        let result = true;

        if (obj.contactName == null || obj.contactName === '') {
            error.contactName = 'Contact Name is required';
            result = false;
        }
        if (obj.contactNumber == null || obj.contactNumber === '') {
            error.contactNumber = 'Contact Number is required';
            result = false;
        }
        if (obj.address == null || obj.address === '') {
            error.address = 'Address is required';
            result = false;
        }
        if (obj.city == null || obj.city === '') {
            error.city = 'City is required';
            result = false;
        }
        if (!onlyCheckCity) {
            if (obj.district == null || obj.district === '') {
                error.district = 'District is required';
                result = false;
            }
            if (obj.ward == null || obj.ward === '') {
                error.ward = 'Ward is required';
                result = false;
            }
        }

        return result;
    }
};

export class LocCodesModel {
    constructor() {
        this.businessLicensePOICode = '';
        this.pharmaBusinessLicensePOICode = '';
        this.gdpGppGspPOICode = '';
        this.treatmentLicensePOICode = '';
        this.medicalLicensePOICode = '';
        this.wardCode = '';
        this.districtCode = '';
        this.cityCode = '';
        this.stateCode = '';
        this.countryCode = CountryCodes.VIETNAM_COUNTRY_CODE;
    }
};